/* otra forma de scroll behavior */

document.addEventListener('DOMContentLoaded', function(){
	scrollNav();
});

function scrollNav (){
	const enlaces = document.querySelectorAll('.menu li a');

	enlaces.forEach ( function (enlace){
		enlace.addEventListener ('click', function(e){
			e.preventDefault();
			const seccion = document.querySelector(e.target.attributes.href.value);

			seccion.scrollIntoView({
				behavior: 'smooth',
			});
		});
	});
}