document.addEventListener('DOMContentLoaded', function(){
	limpiarErrores();
	validar();
});
function limpiarErrores(){
	var errores = document.getElementsByClassName("error");
	for(var i = 0; i < errores.length; i++){
	  errores[i].innerHTML = "";
	}
}

function validar(formulario) {
 
	limpiarErrores();
 
	if (formulario.nombre.value.trim().length == 0) {
	  document.getElementById("parrafoError").innerText = " ** CAMPO NOMBRE OBLIGATORIO ** ";
	  formulario.nombre.focus();
	  return false;
	}
 
	if (formulario.nombre.value.trim().length < 5) {
	  document.getElementById("parrafoError").innerText = " CAMPO NOMBRE NO VÁLIDO: DEBE TENER AL MENOS 5 CARACTERES ";
	  formulario.nombre.focus();
	  return false;
	}

	if (formulario.telefono.value.trim().length == 0) {
		document.getElementById("parrafoError").innerText = " ** CAMPO TELÉFONO OBLIGATORIO ** ";
		formulario.nombre.focus();
		return false;
	}
  
	if (formulario.telefono.value.trim().length < 7) {
		document.getElementById("parrafoError").innerText = " CAMPO TELÉFONO NO VÁLIDO: DEBE TENER AL MENOS 7 DIGITOS ";
		formulario.nombre.focus();
		return false;
	}

	var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	if (formulario.email.value.trim().length == 0) {
		document.getElementById("parrafoError").innerText = " ** CAMPO EMAIL OBLIGATORIO ** ";
		formulario.nombre.focus();
		return false;
	}
	if (!re.test(formulario.email.value)) {
		document.getElementById("parrafoError").innerText = " CAMPO CORREO NO VÁLIDO ";
		formulario.email.focus();
		return false;
	}

	if (formulario.npersonas.value.trim().length == 0) {
		document.getElementById("parrafoError").innerText = " ** CAMPO NÚMERO DE PERSONAS OBLIGATORIO ** ";
		formulario.nombre.focus();
		return false;
	}

	
	if (formulario.paquete.value == "") {
		document.getElementById("parrafoError").innerText = " ** SELECCIONE UN PAQUETE ** ";
		formulario.pais.focus();
		return false;
	}
	if (formulario.ciudad.value == "") {
		document.getElementById("parrafoError").innerText = " ** SELECCIONE UNA CIUDAD ** ";
		formulario.pais.focus();
		return false;
	}

	if (formulario.llegada.value.trim().length == 0) {
		document.getElementById("parrafoError").innerText = " ** SELECCIONE FECHA DE LLEGADA ** ";
		formulario.nombre.focus();
		return false;
	}

	if (formulario.salida.value.trim().length == 0) {
		document.getElementById("parrafoError").innerText = " ** SELECCIONE FECHA DE SALIDA ** ";
		formulario.nombre.focus();
		return false;
	}
	
	alert("Datos enviados");
 
	return true;
}
 