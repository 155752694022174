document.addEventListener('DOMContentLoaded', function(){
	crearGaleria(); //Funcion crear galeria
});

function crearGaleria (){
	const galeria = document.querySelector('.galeria-imagenes');

	for ( let i = 1; i <= 12; i++){
		/* Creacion de las imagenes dentro de la clase .galeria-imagenes */
		const imagen = document.createElement('IMG');
		imagen.src = `build/img/${i}.webp`;
		// Para indicar a que imagen se le esta dando clic
		// en el documento HTML en la etiqueta img de la galeria se crea un atributo nuevo llamado data-imagen-id= "6" asignandole un numero automaticamente
		imagen.dataset.imagenId = i;

		// Añadir funcion de mostrar imagen
		imagen.onclick = mostrarImagen;

		/* Mostrar las imagenes en la pagina web */
		const lista = document.createElement('LI');
		lista.appendChild(imagen);

		galeria.appendChild(lista);
	}
} 

/* Funcion para mostrar imagen grande cuando le demos click */

function mostrarImagen(e){
	// console.log(e.target.dataset);
	//console.log(e.target.dataset.imagenId);
	
	const id = parseInt(e.target.dataset.imagenId);

	const imagen = document.createElement('IMG');
	imagen.src = `build/img/${id}.webp`;

	//console.log(imagen);

	/* Crear fondo oscuro cuando se muestra la imagen grande */

	const overlay = document.createElement('DIV');
	overlay.appendChild(imagen);
	overlay.classList.add('overlay'); //Se crea la clase CSS

	/* Boton para cerrar imagen */
	const cerrarImagen = document.createElement('P');
	cerrarImagen.textContent = 'X';
	cerrarImagen.classList.add('btn-cerrar');

	overlay.appendChild(cerrarImagen);

	/* cuando se presiona el boton X se cierra la imagen */
	cerrarImagen.onclick = function(){
		overlay.remove();
	}
	/* Cuando se da clic en cualquier parte de la pantalla para cerrar la imagen */
	overlay.onclick = function(){
		overlay.remove();
	}

	// mostrar en HTML
	const body = document.querySelector('body');
	body.appendChild(overlay);
	/* Quitar scroll cuando se abre la imagen */
	body.classList.add('fijar-body');
}

